<template>
	<div class="footers">
		<footer class="main-footer">
			<div class="row">
				<div class="columns column2">
					<p>&copy; 2025 Maxwell</p>
				</div>
				<div class="columns column5 content">
					<div v-parse-links v-html="defaults.footerContent" />
				</div>
				<div class="columns column3 contact">
					<p>
						{{ defaults.street }} {{ defaults.number }} {{ defaults.city }} <br />
						<a v-if="defaults.email" :href="`mailto:${defaults.email}`">
							{{ defaults.email }}
						</a>
						<a v-if="defaults.phone" :href="`tel:${$phoneRaw(defaults.phone)}`">
							{{ defaults.phone }}
						</a>
					</p>
					<social-media :socials="socials" />
				</div>
				<div class="columns column2">
					<div v-if="page" class="language-navigation" @click="toggleLanguageMenu">
						<div v-if="langmenu.length" class="lang-select">
							<font-awesome-icon :icon="`fal fa-globe`" size="1x" />
							<span>{{ $t(locale) }}</span>
							<font-awesome-icon :icon="`fal fa-chevron-down`" size="1x" />
							<div class="lang-wrapper" :class="{ active: showLanguageMenu }">
								<div v-for="item in langmenu" :key="item.filename" class="language">
									<nuxt-link :class="item.language" :to="item.filename">
										<span>{{ $t(item.language) }}</span>
									</nuxt-link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
	</div>
</template>

<script setup>
defineProps({
	defaults: { type: Object, default: () => {} },
	socials: { type: Array, default: () => [] },
});

/* const { data: footermenu } = await useWebsiteFetch('footermenu'); */
const { locale } = useI18n();

const { page } = usePage();

const showLanguageMenu = ref(false);

const langmenu = computed(() => {
	return (page.value && page.value.langmenu) ?? [];
});

const toggleLanguageMenu = () => {
	showLanguageMenu.value = !showLanguageMenu.value;
};
</script>

<style lang="scss" scoped>
.footers {
	color: #f2f3ec;
	background: #749779;
	padding: 25px 0 5px;
	font-size: 18px;

	.column2:first-child {
		display: flex;
		align-items: flex-end;
		font-family: big-caslon-fb, serif;
	}

	a,
	span {
		color: #f2f3ec;
		text-decoration: none;
	}

	h3,
	h4,
	:deep(h3),
	:deep(h4) {
		color: #f2f3ec;
		letter-spacing: 1px;
	}

	h4,
	:deep(h4) {
		font-family: var(--body-font-family);
		font-size: 1.3em;
	}

	.row {
		max-width: 1280px;
	}

	p,
	:deep(p) {
		line-height: 22px;
	}
}

.main-footer {
	p,
	:deep(p) {
		font-size: 0.9em;
	}

	a,
	span {
		display: block;
	}

	a:hover {
		text-decoration: underline;
	}

	.footer-logo {
		max-width: 180px;
		margin: 0 0 30px;
	}

	.row {
		max-width: 1800px;
	}
}

.contact {
	font-weight: 300;
}

.content {
	text-align: right;
	font-weight: 300;
}

.bottomfooter-row {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 0 20px;

	p {
		margin: 0;
	}

	.align-left {
		p {
			margin: -3px 0 3px;
		}
	}

	.menu {
		margin-left: auto;
	}
}

.menu-divider {
	margin: 0 5px;
}

.lang-select {
	margin: 10px 0 0;
	position: relative;
	cursor: pointer;
	font-size: 16px;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	color: #fff;
	border: 1px solid #fff;
	width: 190px;
	padding: 14px 18px;
	justify-content: space-between;

	span {
		margin: 0 4px;
	}

	a {
		color: #231f20;
		display: flex;
		flex-flow: row nowrap;
		gap: 8px;
		margin: 7px 0;
		place-content: center flex-start;
		width: 100%;
		text-decoration: none;
	}

	svg {
		font-size: 16px;
	}

	.language {
		margin: 0;
		padding: 8px 18px;
		transition: all 0.3s ease-in-out;

		&:hover {
			background: #e3e3e3;
		}
	}
}

.lang-wrapper {
	display: none;
	position: absolute;
	bottom: 47px;
	left: -1px;
	right: -1px;
	padding: 0;
	text-align: left;
	background: #fff;
	z-index: 90;
	border-radius: 0;
	border: 1px solid #fff;

	&.active,
	a {
		display: flex;
		flex-flow: column nowrap;
		place-content: flex-start;
		color: #000 !important;
		font-size: 13px;
		gap: 5px;
	}

	a {
		width: 100%;
		flex-direction: row;
		color: #000;

		span {
			color: #000;
		}
	}

	img {
		width: 16px;
		height: 16px;
		margin: 2px 0 0;
	}
}

.lang-nav,
.lang-select {
	a {
		width: 100%;
		float: left;
		color: var(--body-color);
		text-decoration: none;
		margin: 4px 0;

		&.active {
			color: var(--cta-color);
		}
	}
}

.language-navigation .fa-globe {
	margin: 0 2px 0 0;
	color: var(--body-color);
	transition: color 0.3s ease-in-out;
}

@media (max-width: 980px) {
	.footers {
		padding: 15px 0 5px;
		margin-top: 150px;

		.column2:first-child {
			text-align: center;
			margin: 10px auto 0;
			width: 100%;
			align-items: center;
			justify-content: center;
		}

		.language-navigation {
			margin: 0 auto 45px;

			.lang-select {
				margin: 20px auto 0;
			}
		}
	}

	footer {
		text-align: center;

		.columns {
			margin: 5px auto;
			width: 50%;
		}
	}
}

@media (max-width: 580px) {
	footer .columns {
		width: 100%;
	}
}
</style>
