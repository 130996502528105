<template>
	<div v-if="props.page.slug === '404'">
		<div class="row">
			<div class="columns column12">
				<nuxt-link class="logo" :to="{ path: localePath('/', locale) }" />
			</div>
		</div>
		<main-content :title="props.page.title || props.page.header" :content="props.page.content" :defaults="{}" />

		<footers :socials="socials" :defaults="defaults[locale].website" />
	</div>
	<div v-else>
		<slot />
	</div>
</template>

<script setup>
const props = defineProps({
	page: { type: Object, default: () => {} },
});

const { fetchPage } = usePage();
const { defaults, socials, fetchDefaults } = useDefaults();

const { locale } = useI18n();

await fetchPage();

if (!defaults.value) {
	await fetchDefaults();
}
</script>

<style lang="scss" scoped>
.logo {
	width: 1400px;
	height: 540px;
	max-width: 80%;
	background: url('~/assets/images/logo-maxwell-cafe-black-full.png') no-repeat center center;
	background-size: 100%;
	margin: 20px 0 101px 50px;
	position: relative;
	z-index: 2;
	display: block;
}
</style>
